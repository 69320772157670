var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"login"}},[_c('div',{staticClass:"login-body"},[_c('div',{staticClass:"my-container main-body"},[(_vm.$store.state.Auth.isLocked)?[_c('a-alert',{staticClass:"error_alert",attrs:{"message":"Uh-oh... :(","type":"info"}},[_c('template',{slot:"description"},[_vm._v(" We apologize, but due to too many incorrect attempts, you will only be able to try again after "),(_vm.$store.state.Auth.confirmationCreds.unlock_date)?[_vm._v(" "+_vm._s(_vm.$store.state.Auth.confirmationCreds.unlock_date)+". ")]:[_vm._v("10 days.")]],2)],2)]:(_vm.errorMessage)?[_c('a-alert',{staticClass:"error_alert",attrs:{"message":"Uh-oh... :(","type":"info"}},[_c('p',{attrs:{"slot":"description"},slot:"description"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.errorMessage)}})])])]:_vm._e(),(_vm.current == 1)?[_c('h1',{staticClass:"main-body__title"},[_vm._v(_vm._s(_vm.mainText)+". "+_vm._s(_vm.subText))])]:_vm._e(),_c('a-tabs',{staticClass:"the-tabs",attrs:{"default-active-key":1,"activeKey":_vm.current,"type":"line","tab-position":"bottom"}},[_c('a-tab-pane',{key:_vm.emailTab},[_c('Loader',{attrs:{"active":_vm.loading}}),_c('div',{staticClass:"tab-text",attrs:{"slot":"tab"},slot:"tab"}),_c('div',{staticClass:"form-group"},[_c('h1',{staticClass:"form-group__title"},[_vm._v(" Please enter the email address you used on your most recent Kupiku order. ")]),_c('a-input',{staticClass:"form-group__input",attrs:{"name":"email","size":"large","placeholder":"email@someone.com"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.emailCheck.apply(null, arguments)},"change":_vm.userTyped},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('div',{staticClass:"form-group__btn-group"},[_c('a',{class:{
                  'login-step-btn': true,
                  disabled: !_vm.shouldNext('email'),
                },attrs:{"slot":"nextClick"},on:{"click":function($event){$event.preventDefault();return _vm.emailCheck.apply(null, arguments)}},slot:"nextClick"},[_vm._v(" Next "),_c('i',{staticClass:"fas fa-arrow-right icon-right"})])])],1)],1),_c('a-tab-pane',{key:_vm.codeTab},[_c('Loader',{attrs:{"active":_vm.loading}}),_c('div',{staticClass:"tab-text",attrs:{"slot":"tab"},slot:"tab"}),_c('div',{staticClass:"form-group"},[_c('h1',{staticClass:"form-group__title"},[_vm._v(" What was the postal code on your most recent shipped order? ")]),_c('a-input',{staticClass:"form-group__input",attrs:{"size":"large","placeholder":"1232","name":"postalCode"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.postalCheck.apply(null, arguments)},"change":_vm.userTyped},model:{value:(_vm.postalCode),callback:function ($$v) {_vm.postalCode=$$v},expression:"postalCode"}}),_c('div',{staticClass:"form-group__btn-group"},[_c('a',{staticClass:"login-step-back",on:{"click":function($event){$event.preventDefault();return _vm.back.apply(null, arguments)}}},[_c('i',{staticClass:"fas fa-arrow-left icon-left"}),_vm._v(" Back ")]),_c('a',{class:{
                  'login-step-btn': true,
                  disabled: !_vm.shouldNext('postalCode'),
                },on:{"click":function($event){$event.preventDefault();return _vm.postalCheck.apply(null, arguments)}}},[_vm._v(" Next "),_c('i',{staticClass:"fas fa-arrow-right icon-right"})])])],1)],1),_c('a-tab-pane',{key:_vm.titleTab},[_c('Loader',{attrs:{"active":_vm.loading}}),_c('div',{staticClass:"tab-text",attrs:{"slot":"tab"},slot:"tab"}),_c('div',{staticClass:"form-group"},[_c('h1',{staticClass:"form-group__title"},[_vm._v(" What was the title or the artist on one of the items in that order? ")]),_c('a-input',{staticClass:"form-group__input",attrs:{"name":"artist_title","size":"large"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.confirmIdentity.apply(null, arguments)},"change":_vm.userTyped},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c('div',{staticClass:"form-group__btn-group"},[_c('a',{staticClass:"login-step-back",on:{"click":function($event){$event.preventDefault();return _vm.back.apply(null, arguments)}}},[_c('i',{staticClass:"fas fa-arrow-left icon-left"}),_vm._v(" Back ")]),_c('a',{class:{
                  'login-step-btn': true,
                  disabled: !_vm.shouldNext('title'),
                },on:{"click":function($event){$event.preventDefault();return _vm.confirmIdentity.apply(null, arguments)}}},[_vm._v(" Gimme Rewards! "),_c('i',{staticClass:"fas fa-gift icon-right"})])])],1)],1)],1),_c('div',{attrs:{"id":"contact"}},[_c('div',{staticClass:"help-text"},[_vm._v("Need Help?")]),_c('router-link',{staticClass:"contact-button",attrs:{"to":{ name: 'ContactUs' }}},[_c('i',{staticClass:"fas fa-comment-alt"})])],1)],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }