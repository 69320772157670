<script>
import api from "../api";
import Loader from "vue-element-loading";
import AInput from "ant-design-vue/lib/input";
import AInputPassword from "ant-design-vue/lib/input";
import AButton from "ant-design-vue/lib/button";
import Message from "ant-design-vue/lib/message";
export default {
  component: {
    Message,
  },
  components: {
    AInput,
    AInputPassword,
    AButton,
    Loader,
  },
  data() {
    return {
      loading: false,
      email: "",
      password: "",
    };
  },
  methods: {
    submit() {
      this.loading = true;
      const data = { email: this.email, password: this.password };
      api
        .checkPassword(data)
        .then((res) => {
          console.log(res);
          this.$store.commit("Auth/SET_TOKEN_FOR_RETURN", res.data.token);
          localStorage.setItem("auth", res.data.token);
          this.$store.dispatch("Auth/UserStats");
          this.$store.dispatch("Cart/setCart");
          this.loading = false;
          this.$router.push({ name: "Home" });
        })

        .catch((error) => {
          this.loading = false;
          if (error.response.status === 500) {
            Message.warning("Oops, bumped into an error");
          } else if (error.response.status === 404) {
            Message.warning(`${error.response.data.message}, try again?`);
          }
        });
    },
  },
  created() {
    this.$store.commit("Nav/SET_PAGE", "login"),
      this.$store.commit("Nav/SET_ACTIVE", 4);
  },
};
</script>
<template>
  <div id="login">
    <div class="form-area">
      <loader :active="loading" spinner="ring" />
      <h1 class="title">
        Welcome Back!
      </h1>
      <div class="group">
        <div class="inner">
          <h2 class="form-label">Email</h2>
          <a-input class="input-medium" v-model="email" name="email">
            <i class="fas fa-envelope" slot="prefix" />
          </a-input>
        </div>
      </div>
      <div class="group">
        <div class="inner">
          <h2 class="form-label">Password</h2>
          <a-input-password
            type="password"
            name="password"
            v-on:keyup.enter="submit"
            class="input-medium"
            v-model="password"
          >
            <i class="fas fa-lock" slot="prefix" />
          </a-input-password>
        </div>
      </div>

      <div class="group">
        <a href="" class="blue-button" @click.prevent="submit">Log In</a>
        <a-button
          type="link"
          class="small-btn-link"
          @click.prevent="$router.push({ name: 'GettingStarted' })"
          >New User/Forgot Password?</a-button
        >
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "../styles/_mixins.scss";
@import "../assets/styles/_utilities.scss";
@import "../assets/styles/_components.scss";
@import "@/assets/styles/_formTools";
#login {
  position: relative;
  top: 12.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  // font-family: var(--baloo);
}
.title {
  letter-spacing: 0.2rem;
  font-size: 2.9rem;
  color: var(--color-primary-dark);
}
.small-btn-link {
  margin-top: 1.3rem;
}
</style>
