<script>
import Loader from "vue-element-loading";
import { mapGetters } from "vuex";
import ATabs from "ant-design-vue/lib/tabs";
import ATabPane from "ant-design-vue/lib/vc-tabs/src/TabPane";
import AInput from "ant-design-vue/lib/input";
import AAlert from "ant-design-vue/lib/alert";
export default {
  name: "Login",
  components: {
    ATabs,
    ATabPane,
    AInput,
    AAlert,
    Loader,
  },
  data() {
    return {
      error500Msg: "Something went wrong on our end. We'll get to it shortly.",
      canNext: true,
      current: 1,
      currentState: "wait",
      emailTab: 1,
      codeTab: 2,
      titleTab: 3,
      isDisabled: true,
      typed: false,
      loading: false,
      prevStep: 0,
      step: 1,
      failCount: 0,
      failStep: 0,
      error: null,
      email: "",
      postalCode: "",
      title: "",
      failed: 0,
      stepStyle: {
        marginTop: "45px",
        marginBottom: "60px",
        boxShadow: "0px -1px 0 0 #e8e8e8 inset",
      },
      mainText:
        "In order to dig into our treasure chest and see how many Reward Points you've already earned we need to verify you",
      subText: "Don't worry this will be quick and easy.",
      foundCode: false,
      foundEmail: false,
      errorMessage: null,
    };
  },
  computed: {
    ...mapGetters({ hasPassword: "hasPassword" }),

    loggedIn() {
      return this.$store.state.Auth.token;
    },
    emailComplete() {
      const finish = "finish";
      // const wait = "wait";
      const process = "process";
      if (this.step == 0) {
        return process;
      } else {
        return finish;
      }
    },
    postalComplete() {
      const finish = "finish";
      const wait = "wait";
      const process = "process";
      if (this.step < 1) {
        return wait;
      } else if (this.step == 1) {
        return process;
      } else if (this.step == 2) {
        return finish;
      }
      return null;
    },
    titleComplete() {
      const finish = "finish";
      // const wait = "wait";
      // const process = "process";
      return finish;
    },
  },
  watch: {
    email() {
      if (!this.$store.state.Auth.isLocked) {
        this.errorMessage = "";
        this.canNext = true;
      }
    },
    postalCode() {
      if (!this.$store.state.Auth.isLocked) {
        this.errorMessage = "";
      }
    },
    title() {
      if (!this.$store.state.Auth.isLocked) {
        this.errorMessage = "";
      }
    },
  },
  methods: {
    userTyped() {
      // this.typed = true;
      this.errorMessage = "";
    },
    shouldNext(field) {
      if (this.$store.state.Auth.isLocked) {
        return false;
      }
      if (this.errorMessage) {
        return false;
      }
      if (field == "email" && !this.email) {
        return false;
      }
      if (field == "postalCode" && !this.postalCode) {
        return false;
      }
      if (field == "title" && !this.title) {
        return false;
      }
      return true;
    },
    failStepCheck(step) {
      if (this.failStep != step) {
        this.failCount = 0;
      }
      this.failCount += 1;
      this.failStep = step;
      return this.failCount;
    },
    emailCheck() {
      this.loading = true;
      this.prevStep = 0;
      this.$store
        .dispatch("Auth/confirmAuthCreds", {
          step: "email",
          fields: {
            email: this.email,
          },
        })
        .then(() => {
          this.loading = false;
          if (!this.$store.state.Auth.isLocked) {
            this.prevStep = 1;
            this.current = 2;
            this.errorMessage = "";
          }
        })
        .catch((e) => {
          this.failed = 1;
          this.error = e;
          this.loading = false;
          if (e.response.status == 500) {
            this.errorMessage = this.error500Msg;
            return;
          }
          this.failStepCheck(1);
          this.errorMessage = "Are you sure you got the email right?";
        });
    },
    postalCheck() {
      this.loading = true;
      this.prevStep = 1;
      this.$store
        .dispatch("Auth/confirmAuthCreds", {
          step: "postalCode",
          fields: {
            email: this.email,
            postalCode: this.postalCode,
          },
        })
        .then(() => {
          this.loading = false;
          if (!this.$store.state.Auth.isLocked) {
            this.prevStep = 2;
            this.current = 3;
            this.errorMessage = "";
          }
        })
        .catch((e) => {
          this.loading = false;
          this.failed = 1;
          this.error = e;
          if (e.response.status == 500) {
            this.errorMessage = this.error500Msg;
            return;
          }

          this.failStepCheck(2);
          this.loading = false;
          if (this.failCount == 3) {
            this.errorMessage =
              "Please refer back to your Discogs order page, and enter the Postal/Zip Code precisely as found in your order, to continue.";
          } else {
            this.errorMessage = `The Postal/ZIP Code you entered does not match the Postal/ZIP Code in your latest order. Please try again.`;
          }
        });
    },
    back() {
      this.current = this.current - 1;
      this.errorMessage = "";
    },
    redirect() {
      const params = { name: "SetupAccount", params: { email: this.email } };
      const next = localStorage.getItem("next");
      if (this.$store.state.Auth.isFirstTime) {
        params.name = "SetupAccount";
        params.params["isFirstTime"] = true;
        params.params["hasPassword"] = false;
        localStorage.setItem("next", params);
      } else if (this.$store.state.Auth.hasPassword) {
        params.params["isFirstTime"] = false;
        params.params["hasPassword"] = true;
      }

      this.$router.push(params);
    },
    confirmIdentity() {
      this.failed = 0;
      this.loading = true;
      const params = {
        email: this.email,
        postalCode: this.postalCode,
        title: this.title,
      };
      this.errorMessage = "";
      this.$store
        .dispatch("Auth/login", params)
        .then((d) => {
          localStorage.setItem("auth", d.token);
          this.$store.dispatch("Auth/UserStats");
          this.$store.dispatch("Cart/setCart");
          this.redirect();
        })
        .catch((e) => {
          this.failed = 1;
          this.error = e;
          this.loading = false;
          if (e.response.status == 500) {
            this.errorMessage = this.error500Msg;
            return;
          }
          this.failStepCheck(3);
          this.errorMessage =
            "The Title or Artist you entered does not match any item in your recently shipped orders.  Please check the spelling, and try again.";
        });
    },
  },
  // created() {},
  created() {
    this.$store.commit("Nav/SET_PAGE", "login");
    this.$store.commit("Nav/SET_ACTIVE", 1);
  },
};
</script>

<template>
  <div id="login">
    <div class="login-body">
      <div class="my-container main-body">
        <template v-if="$store.state.Auth.isLocked">
          <a-alert class="error_alert" message="Uh-oh... :(" type="info">
            <template slot="description">
              We apologize, but due to too many incorrect attempts, you will
              only be able to try again after
              <template v-if="$store.state.Auth.confirmationCreds.unlock_date">
                {{ $store.state.Auth.confirmationCreds.unlock_date }}.
              </template>
              <template v-else>10 days.</template>
            </template>
          </a-alert>
        </template>
        <template v-else-if="errorMessage">
          <a-alert class="error_alert" message="Uh-oh... :(" type="info">
            <p slot="description">
              <span v-html="errorMessage"></span>
            </p>
          </a-alert>
        </template>

        <!-- <div class="main-text"> -->
        <template v-if="current == 1">
          <h1 class="main-body__title">{{ mainText }}. {{ subText }}</h1>
        </template>
        <!-- <h1 class="main-body__sub">{{subText}}</h1> -->
        <!-- </div> -->
        <a-tabs
          :default-active-key="1"
          :activeKey="current"
          type="line"
          tab-position="bottom"
          class="the-tabs"
        >
          <a-tab-pane :key="emailTab">
            <Loader :active="loading" />
            <div slot="tab" class="tab-text"></div>
            <div class="form-group">
              <h1 class="form-group__title">
                Please enter the email address you used on your most recent
                Kupiku order.
              </h1>
              <a-input
                name="email"
                size="large"
                placeholder="email@someone.com"
                class="form-group__input"
                v-on:keyup.enter="emailCheck"
                v-model="email"
                @change="userTyped"
              />
              <div class="form-group__btn-group">
                <a
                  slot="nextClick"
                  :class="{
                    'login-step-btn': true,
                    disabled: !shouldNext('email'),
                  }"
                  @click.prevent="emailCheck"
                >
                  Next
                  <i class="fas fa-arrow-right icon-right" />
                </a>
              </div>
            </div>
          </a-tab-pane>
          <a-tab-pane :key="codeTab">
            <Loader :active="loading" />
            <div slot="tab" class="tab-text"></div>
            <div class="form-group">
              <h1 class="form-group__title">
                What was the postal code on your most recent shipped order?
              </h1>
              <a-input
                size="large"
                placeholder="1232"
                class="form-group__input"
                name="postalCode"
                v-on:keyup.enter="postalCheck"
                @change="userTyped"
                v-model="postalCode"
              />
              <div class="form-group__btn-group">
                <a class="login-step-back" @click.prevent="back">
                  <i class="fas fa-arrow-left icon-left" />
                  Back
                </a>
                <a
                  :class="{
                    'login-step-btn': true,
                    disabled: !shouldNext('postalCode'),
                  }"
                  @click.prevent="postalCheck"
                >
                  Next
                  <i class="fas fa-arrow-right icon-right" />
                </a>
              </div>
            </div>
          </a-tab-pane>
          <a-tab-pane :key="titleTab">
            <Loader :active="loading" />
            <div slot="tab" class="tab-text"></div>
            <div class="form-group">
              <h1 class="form-group__title">
                What was the title or the artist on one of the items in that
                order?
              </h1>
              <a-input
                name="artist_title"
                size="large"
                class="form-group__input"
                v-on:keyup.enter="confirmIdentity"
                @change="userTyped"
                v-model="title"
              />
              <div class="form-group__btn-group">
                <a class="login-step-back" @click.prevent="back">
                  <i class="fas fa-arrow-left icon-left" />
                  Back
                </a>
                <a
                  :class="{
                    'login-step-btn': true,
                    disabled: !shouldNext('title'),
                  }"
                  @click.prevent="confirmIdentity"
                >
                  Gimme Rewards!
                  <i class="fas fa-gift icon-right" />
                </a>
              </div>
            </div>
          </a-tab-pane>
        </a-tabs>
        <div id="contact">
          <div class="help-text">Need Help?</div>
          <router-link :to="{ name: 'ContactUs' }" class="contact-button">
            <i class="fas fa-comment-alt"></i>
          </router-link>
        </div>
      </div>
    </div>

    <!-- <div id="contact">
      <router-link :to="{name: 'ContactUs'}" class="btn btn-primary">Contact Us</router-link>
    </div>-->
  </div>
</template>

<style lang="scss" scoped>
@import "../styles/_mixins.scss";
@import "../assets/styles/_utilities.scss";
@import "../assets/styles/_components.scss";
#login {
  //   max-width: 100%;
  position: relative;
  top: 9.1rem;
}

.nav-container {
  background: var(--color-blue);
}
.the-tabs {
  // width: 320px;
  @include respond(tab_p) {
  }
  @include respond(tab_l) {
  }
  @include respond(phone) {
    width: 32rem;
  }
}
.tab-text {
  width: 320px;
  @include respond(tab_p) {
  }
  @include respond(tab_l) {
  }
  @include respond(phone) {
    width: 23rem;
  }
}
.error_alert {
  margin-top: -2.2rem;
  margin-bottom: 1.9rem;
  width: 87rem;
  @include respond(tab-p) {
    width: 67rem;
    margin-bottom: 1.2rem;
  }
  @include respond(tab-l) {
  }
  @include respond(phone) {
    margin-bottom: 1.5rem;
    width: 27rem;
  }
  @include respond(bg-dt) {
  }
}
// .icon {
//   margin-left: 0.6rem;
// }
input {
  margin-bottom: 10px;
}
#failure {
  border-radius: 3px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 10px;
}
.my-container {
  margin: 0 9rem;
  position: relative;
}
.home {
  position: relative;
  width: 100%;
  background: var(--color-light-grey);
}

.form-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 3em;
  align-items: center;
  &__title {
    margin-top: -3rem;
    font-size: 2rem;
    font-weight: 200;
    font-family: "Baloo Tammudu 2", "Source Sans Pro", sans-serif;
    @include respond(tab-p) {
    }
    @include respond(tab-l) {
    }
    @include respond(phone) {
      width: 29.5rem;
      font-size: 2rem;
      text-align: center;
    }
    @include respond(bg-dt) {
    }
  }
  &__input {
    width: 50rem;
    @include respond(tab-p) {
    }
    @include respond(tab-l) {
    }
    @include respond(phone) {
      width: 27rem;
    }
    @include respond(bg-dt) {
    }
  }
  &__btn-group {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    font-size: 1.6rem;
    // width: 16rem;
    text-align: center;
    @include respond(phone) {
    }
    @include respond(tab-p) {
    }
    @include respond(tab-l) {
    }
    @include respond(bg-dt) {
    }
    &--final {
      margin-top: 2rem;
      // width: 18rem;
      font-size: 1.6rem;
      @include respond(phone) {
      }
      @include respond(tab-p) {
      }
      @include respond(tab-l) {
      }
      @include respond(bg-dt) {
      }
    }
  }
  @include respond(phone) {
  }
  @include respond(tab-p) {
  }
  @include respond(tab-l) {
  }
  @include respond(bg-dt) {
  }
}
.main-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  //   margin-top: 8.6rem;
  &__title {
    text-align: center;
    width: 85.8rem;
    font-family: "Baloo Tammudu 2", cursive;
    font-weight: 400;
    letter-spacing: 0.03em;
    line-height: 3rem;
    font-size: 2.4rem;
    @include respond(bg-dt) {
      font-size: 2.6rem;
    }
    @include respond(tab-p) {
      margin-top: 3.7rem;
      width: 71rem;
    }
    @include respond(phone) {
      width: 38.5rem;
      font-size: 2.2rem;
    }
    @include respond(tab-l) {
    }
  }
  &__sub {
    margin-top: 3.6rem;
    font-family: "Baloo Tammudu 2", sans-serif;
    font-size: 2.1rem;
    font-weight: 400;
    @include respond(phone) {
      text-align: center;
      margin-top: 1rem;
      font-size: 2rem;
    }
    @include respond(tab-p) {
    }
    @include respond(tab-l) {
    }
    @include respond(bg-dt) {
    }
  }
  @include respond(tab-p) {
  }
  @include respond(tab-l) {
  }
  @include respond(phone) {
    margin-top: 12rem;
  }
  @include respond(bg-dt) {
  }
}
.main-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1.5rem;
  align-items: center;
}
.nav_container {
  overflow: hidden;
  position: fixed;
  width: 100%;
  top: 0;
  height: 5.5rem;
  background: rgb(218, 68, 83); /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #da4453,
    #89216b
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #da4453, #89216b);
  // filter: blur(8px);
  // -webkit-filter: blur(8px);
  backdrop-filter: blur(3px);
  box-shadow: 0 0.1rem 0.6rem rgba(0, 0, 0, 0.17);
  font-size: 1.8rem;
  font-family: "Source Sans Pro", sans-serif;
  align-items: center;
  transition: background 0.5s;
  flex-wrap: wrap;
  z-index: 20;

  nav {
    position: relative;
    padding: 0.8rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 28;
    .logo {
      display: block;
      height: 3.2rem;
    }
    .items {
      a {
        text-decoration: none;
      }
      .list-item-end {
        position: relative;
        color: #fff;
        font-weight: 500;
        &:hover {
          border-bottom: 0.5em solid var(--color-spring-green);
        }
      }
      .badge {
        text-align: center;
        position: absolute;
        height: 1.1rem;
        width: 1.1rem;
        top: 0.1em;
        left: 0.35em;
        border-radius: 50%;
        background: var(--color-spring-green);
      }
      .list-item-end:not(:last-of-type) {
        text-decoration: none;
        margin-right: 2rem;
      }
    }
  }
  .change_color {
    background: #89216b;
  }
}
.error {
  font-size: 14px;
}
#contact {
  .btn {
    font-size: 14px;
    border-radius: 3px;
  }
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 1.2rem;
  right: 1.2rem;
  .help-text {
    font-family: var(--open-sans);
    font-size: 1.4rem;
    margin-right: 1rem;
    @include respond(phone) {
      display: none;
    }
  }
  @include respond(phone) {
    align-self: flex-end;
  }
}
</style>
